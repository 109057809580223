<template>
  <div class="dashboard-slider-tags">
    <div
      class="scroll-btn"
      @click="scrollLeft"
    >
      <v-icon> mdi-chevron-left </v-icon>
    </div>
    <div class="content-wrapper">
      <draggable
        v-model="dashboardSort.section_1"
        group="card-boxes"
        :class="isDisable ? 'section-one-in-active' : ''"
        :disabled="isDisable"
        class="draggable-wrapper"
        @end="updateOrder"
      >
        <v-card
          v-for="(row, i) in items"
          :key="i"
          :loading="isLoading"
          class="Tag_card"
        >
          <div :class="['itemBoxes', getCardColor(row.keys[0])]">
            <div class="iconBox">
              <Icons :name="getCardName(row.card_name)" />
            </div>
            <div class="textBox">
              <p>{{ row.card_name }}</p>
              <div v-if="row.card_name !== 'Upcoming Maintenance'">
                <span v-if="vehicleTech == 'ice' && row.vehicle_tech == 'ice'">
                  {{ $t(`units.${row.keys[0]}-${vehicleTech}`) | currencyFormat(authUser) }}
                </span>
                <span v-else>
                  {{ $t(`units.${row.keys[0]}`) | currencyFormat(authUser) }}
                </span>
              </div>
            </div>
            <div class="valueBox">
              {{ dashboadSectionOne[row.keys[0]] }}
            </div>
          </div>
        </v-card>
      </draggable>
    </div>
    <div
      class="scroll-btn"
      @click="scrollRight"
    >
      <v-icon> mdi-chevron-right </v-icon>
    </div>
  </div>
</template>
  
  <script>
  import { mapGetters } from "vuex";
  import draggable from "vuedraggable";
  export default {
    components: {
      draggable,
      Icons: () => import("@/components/base/icons.vue"),
    },
    props: {
      isLoading: {
        type: Boolean,
        default() {
          return false;
        },
      },
      items: {
        type: Array,
        default() {
          return [];
        },
      },
      vehicleTech: {
        type: String,
        default() {
          return "";
        },
      },
    },
    computed: {
      ...mapGetters({
        authUser: "getUser",
        dashboadSectionOne: "dashboard/getDashboadSectionOne",
        dashboardSort: "dashboard/getDashboadSortData",
        selectedCards: "dashboard/getSelectedCards",
      }),
      isDisable() {
        return this.selectedCards.length == 0;
      },
    },
    methods: {
      scrollLeft() {
        document.getElementsByClassName("content-wrapper")[0].scrollTo({ left: 0, behavior: "smooth" });
      },
      scrollRight() {
        const t = document.getElementsByClassName("content-wrapper")[0];
        const sl = t.scrollLeft + 250;
        t.scrollTo({ left: sl, behavior: "smooth" });
      },
      getCardName(name) {
        return name.toLowerCase().replace(/\s+/g, '-');
      },
      getCardColor(key) {
        const colorMap = {
          "avg_efficincy_in_pkr_kg": "bluebg",
          "average_mileage": "orangebg",
          "avg_efficincy_in_pkr_km": "greenbg",
          "total_avg_speed": "redbg",
          "total_avg_range": "otherBluebg",
          "upcoming_maintenance": "greybg",
          "avg_time_to_charge": "purplebg"
        };
        return colorMap[key] || "defaultbg";
      },
      updateOrder() {
        const params = {
          section: "section_1",
          sort: true,
          item: this.dashboardSort.section_1,
          vehicle_tech: this.vehicleTech,
        };
        this.$store.dispatch("dashboard/updateCard", params);
      },
    },
  };
  </script>
  <style lang="sass" scoped>
  .dashboard-slider-tags
    display: flex
    .scroll-btn
      cursor: pointer
      flex-basis: 30px
      display: flex
      justify-content: center
      align-items: center
      i
        font-size: 2.375rem
        color: rgba(34,34,34,0.25)
    &:hover
      i
        color: rgba(34,34,34,0.95) !important
    .content-wrapper
      overflow-x:  auto
      gap: 0
      display: unset
      width: 100%
      .draggable-wrapper
        display: flex
        gap: 0
      .Tag_card
        min-width: 16.666667%
        cursor: pointer
        transition: 0.7s all ease
        flex: 0 0 16.6666666667%
        max-width: 16.6666666667%
        width: 100%
        padding: 12px
        border-radius: 0
        background: transparent
        box-shadow: 0 0 0 0 !important
        margin: 0
        border-color: transparent
  </style>
  <style scoped>
  .content-wrapper {
    display: flex;
    gap: 1rem;
    overflow-x: auto;
  }
  
  .scroll-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Tag_card {
    min-width: 170px;
    cursor: pointer;
    transition: 0.7s all ease;
  }
  
  .Tag_card:hover {
    transform: scale(1.05);
    box-shadow: 2px 1px 8px rgba(0, 0, 0, 0.2);
  }
  
  .itemBoxes {
    flex: 1 1 200px;
    padding: 10px;
    text-align: center;
  }
  
  .draggable-wrapper {
    display: flex;
    gap: 10px;
  }
  
  .section-one-in-active {
    opacity: 0.6;
    pointer-events: none;
  }
  
  /* Styling for the custom scrollbar */
  .content-wrapper::-webkit-scrollbar {
    display: none;
  }
  </style>
  